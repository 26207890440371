import React from 'react'
import CmsCtaMegaMenu from '../../../CmsComponents/CmsCtaMegaMenu'
import {
  BottomContentBookAnAppointment,
  CtaBookAnAppointment,
  IconTextBookAnAppointment,
  TextBookAnAppointment,
  TitleBookAnAppointment,
  TopContentBookAnAppointment,
  WrapperBookAnAppointment,
} from './BookAnAppointmentSearchBox.style'
import IconWithText from './components/IconWithText'
import { ACCOUNT_CHILDREN } from '../../../../../constants/routes'
import { useDispatch } from 'react-redux'
import { setCloseDrawerMegaMenu, setHamburgerMenuOpenStatus } from '../../../../../features/ui/action'
import { useGoogleSuggestions } from '../../../../../hooks/useGoogleSuggestions'
import { useStoreIdentity } from '../../../../../foundation/hooks/useStoreIdentity'
import { FindStoreInputStyled } from './BookAnAppointmentSearchBox.style'
import { IPlacement, isCMCollection, isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import { normalizedTeaserText } from '@utils/helpers'

export const AutoSuggester: React.FC<{ onSearch?: () => void }> = ({ onSearch }) => {
  const storeCountry = useStoreIdentity().country.toUpperCase()

  const { gmapLoaded, googleAPISuggestionEnabled } = useGoogleSuggestions({
    country: storeCountry,
    page: ACCOUNT_CHILDREN.STORES,
    state: '',
  })

  if (!gmapLoaded || !googleAPISuggestionEnabled) return null

  return (
    <>
      <FindStoreInputStyled data-element-id="X_X_MainNav_Search" {...(onSearch && { onSearch: onSearch })} />
    </>
  )
}

const BookAnAppointmentSearchBox: React.FC<{ placement: IPlacement }> = ({ placement }) => {
  const dispatch = useDispatch()

  const viewType = placement?.viewtype || ''
  const placementName = placement?.name || ''
  const teaserItem = placement?.items?.find(isLXTeaser)
  const collectionItem = placement?.items?.find(isCMCollection)

  const title = teaserItem?.teaserTitle1 || ''
  const text = normalizedTeaserText(teaserItem?.teaserText1 || teaserItem?.teaserText2)

  const collectionTitle = collectionItem?.collectionTitle || ''
  const collectionText = normalizedTeaserText(collectionItem?.collectionText)
  const collectionTeasableItems = collectionItem?.teasableItems.filter(isLXTeaser) || []
  const collectionLXCallToActionSettings = collectionItem?.teaserLXCallToActionSettings || []

  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }

  return (
    <WrapperBookAnAppointment {...metaData}>
      <TopContentBookAnAppointment>
        <TitleBookAnAppointment fontSize={14}>{title}</TitleBookAnAppointment>
        <TextBookAnAppointment>{text}</TextBookAnAppointment>
        <AutoSuggester
          onSearch={() => {
            dispatch(setCloseDrawerMegaMenu())
            dispatch(setHamburgerMenuOpenStatus(false))
          }}
        />
      </TopContentBookAnAppointment>
      {collectionItem && (
        <BottomContentBookAnAppointment>
          <TitleBookAnAppointment fontWeight="bold">{collectionTitle}</TitleBookAnAppointment>
          <TextBookAnAppointment>{collectionText}</TextBookAnAppointment>
          <IconTextBookAnAppointment>
            {collectionTeasableItems.map(teasableItem => (
              <React.Fragment key={teasableItem.id}>
                <IconWithText teasableItem={teasableItem} />
              </React.Fragment>
            ))}
          </IconTextBookAnAppointment>
          <CtaBookAnAppointment>
            {collectionLXCallToActionSettings.map((actionSettings, index) => (
              <CmsCtaMegaMenu
                key={`cms-content__${viewType}--cta-${index}`}
                data-element-id={`X_X_1Placement_Banner${index}_CTA`}
                actionSettings={actionSettings}
              />
            ))}
          </CtaBookAnAppointment>
        </BottomContentBookAnAppointment>
      )}
    </WrapperBookAnAppointment>
  )
}

export default BookAnAppointmentSearchBox
