import React from 'react'
import { DialogProps as MuiDialogProps, useTheme } from '@mui/material'
import { StyledContentModalDialog, StyledDialog, StyledIconButton } from './ModalDialog.style'
import { CloseIcon } from '../Icons/close'

export interface ModalProps {
  open: MuiDialogProps['open']
  onClose?: MuiDialogProps['onClose']
  onCloseButton?: () => void
  children?: MuiDialogProps['children']
  onClick?: MuiDialogProps['onClick']
  hugged?: boolean
}

const Modal = (props: ModalProps) => {
  const { open, onClose, onCloseButton, hugged = false, children } = props
  const modalProps = { open, onClose, onCloseButton, hugged }
  const theme = useTheme()

  return (
    <StyledDialog {...modalProps}>
      <StyledContentModalDialog>
        {onCloseButton && (
          <StyledIconButton
            data-analytics_available_call="0"
            className="ModalDialog--close"
            aria-label="close"
            onClick={onCloseButton}
          >
            <CloseIcon htmlColor={theme.palette.text.primary} />
          </StyledIconButton>
        )}
        {children}
      </StyledContentModalDialog>
    </StyledDialog>
  )
}

export default Modal
