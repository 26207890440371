import React from 'react'
import { useDispatch } from 'react-redux'
import { setCloseDrawerMegaMenu } from '../../../../../features/ui/action'
import useMediaByDeviceType from '../../../../../hooks/useMediaByDeviceType'
import { isLXTeaser } from '../../../../../types/cmsPlacement/Placement'
import CMSCommonMedia from '../../../CmsComponents/CmsCommonMedia'
import CmsCtaMegaMenu from '../../../CmsComponents/CmsCtaMegaMenu'
import {
  ImageBigMenuBanner,
  StyledTitleMenuBanner,
  StyledContentBigMenuBanner,
  StyledCtaMenuBanner,
  StyledCloseIcon,
  StyledButtonWrapper,
} from './BigMegaBanner.style'
import { Typography } from '@mui/material'
import useTheme from '@mui/material/styles/useTheme'
import { LinkStyled } from '@components/common/UI/LinkStyled'
import { useTranslation } from 'next-i18next'
import ScrollableContainer from '@components/UI/ScrollableContainer'
import { MegaMenuColumnComponentProps } from '../../../../../layouts/Header/components/NavigationBar/components/NavigationMenuDesktop/components/MegaMenuContent/MegaMenuContent'
import { normalizedTeaserText } from '@utils/helpers'

const BigMenuBanner: React.FC<MegaMenuColumnComponentProps> = ({ placement, enableScroll, scrollGradientColor }) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const placementName = placement?.name || ''
  const viewType = placement?.viewtype || 'default'
  const teaserItem = placement?.items?.find(isLXTeaser)
  const media = useMediaByDeviceType(teaserItem?.media!)
  if (!teaserItem) return null

  const title =
    teaserItem.teaserTitle1 || teaserItem.teaserTitle2 || teaserItem.teaserTitle3 || teaserItem.teaserTitle4 || ''
  const teaserText = teaserItem.teaserText1 || teaserItem.teaserText2 || ''
  const text = normalizedTeaserText(teaserText)
  const teaserLXCallToActionSettings = teaserItem?.teaserLXCallToActionSettings ?? []
  const isExternal = teaserLXCallToActionSettings[0]?.target?.type === 'CMExternalLink'
  const ctaLink = isExternal
    ? teaserLXCallToActionSettings[0]?.target?.url ?? ''
    : teaserLXCallToActionSettings[0]?.target?.formattedUrl ?? ''
  const metaData = {
    'data-lxcm-lv': `${viewType}`,
    'data-cm-metadata': `[{"_":"properties.placement-${placementName}"},{"placementRequest":[{"isInLayout":true,"hasItems":${true},"placementName":"${placementName}"}]}]`,
  }
  const closeMegaMenu = () => {
    dispatch(setCloseDrawerMegaMenu())
  }

  return (
    <ScrollableContainer
      containerCSSProps={{
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(6),
      }}
      gradientColor={scrollGradientColor}
      isEnabled={enableScroll}
    >
      <div {...metaData}>
        <StyledCloseIcon onClick={closeMegaMenu} />
        <ImageBigMenuBanner onClick={closeMegaMenu}>
          {isExternal ? (
            <a
              href={ctaLink}
              data-element-id={'X_X_1Placement_Banner0_IMG'}
              aria-label={t('Header.CMS.PlacementBannerAriaLabel')}
              target={'_blank'}
            >
              <CMSCommonMedia viewType={viewType} type={'BIG_MENU_BANNER'} teaser={teaserItem} media={media} />
            </a>
          ) : (
            <LinkStyled
              href={ctaLink}
              data-element-id={'X_X_1Placement_Banner0_IMG'}
              aria-label={t('Header.CMS.PlacementBannerAriaLabel')}
            >
              <CMSCommonMedia viewType={viewType} type={'BIG_MENU_BANNER'} teaser={teaserItem} media={media} />
            </LinkStyled>
          )}
        </ImageBigMenuBanner>
        <StyledContentBigMenuBanner>
          <StyledTitleMenuBanner variant="h3">{title} </StyledTitleMenuBanner>
          <Typography variant="subtitle1">{text}</Typography>
          <StyledCtaMenuBanner>
            {teaserLXCallToActionSettings?.map((actionSettings, index) => (
              <StyledButtonWrapper key={`cms-content__${viewType}--cta-${index}`}>
                <CmsCtaMegaMenu data-element-id={`X_X_1Placement_Banner${index}_CTA`} actionSettings={actionSettings} />
              </StyledButtonWrapper>
            ))}
          </StyledCtaMenuBanner>
        </StyledContentBigMenuBanner>
      </div>
    </ScrollableContainer>
  )
}

export default BigMenuBanner
